import React from 'react';
import getYouTubeId from 'get-youtube-id';
import YouTube from 'react-youtube';
import BlogHelper from "../helpers/BlogHelper";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiClock , FiUser } from "react-icons/fi";

const BlockContent = require('@sanity/block-content-to-react');

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    youtube: ({node}) => {
      const { url } = node
      const id = getYouTubeId(url)
      return (<YouTube videoId={id} />)
    },
    inlineAd: props => (
      <div className='inline-ad' dangerouslySetInnerHTML={{__html: props.node.html}}>
      </div>
    )
  }
}

class SanityBlogArticle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      article: null
    }

    this.blogHelper = new BlogHelper();
  }
  
  componentDidMount() {
    var articleSlug = this.props.match.params.articleSlug;
    this.blogHelper.getArticle(articleSlug).then(articles => {
      this.setState({ article: articles[0]});
    });
  }
  renderArticle(article) {
    return (
      <div>
        <h1>{article.title}</h1>
        <BlockContent blocks={article.body} serializers={serializers}>
        </BlockContent>
      </div>
    )
  }
  render() {
    return (
      <React.Fragment>
        <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

        {!this.state.article && "Loading..."}

        {this.state.article && <PageHelmet 
          pageTitle={this.state.article.title}
          pageImage={this.state.article.imageUrl}
          pageUrl={`${process.env.PUBLIC_URL}/12tech-blog/${this.state.article.slug.current}`}
          pageType="article"
          pageDescription={this.state.article.description}
        />
        }

        {/* Start Breadcrump Area */}
        {this.state.article && 
          <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" style={{ background: '#101010'}} data-black-overlay="7">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="blog-single-page-title text-center pt--100">
                    <h2 className="title theme-gradient">{this.state.article.title}</h2>
                      <ul className="blog-meta d-flex justify-content-center align-items-center">
                        <li><FiClock />{new Date(this.state.article._createdAt).toDateString()}</li>
                        <li><FiUser />{this.state.article.authorName}</li>
                      </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* End Breadcrump Area */}

        {/*<div style={{ marginTop: '24px', textAlign: 'center'}} dangerouslySetInnerHTML={ { __html: '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=48&l=ur1&category=amazonhomepage&f=ifr&linkID=33d7410c41f935ecb87815f86d7de837&t=store1205-20&tracking_id=store1205-20" width="728" height="90" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>' } }/>*/}

        {this.state.article && <div style={{ margin: '24px 18px', textAlign: 'center', borderStyle: 'solid', borderColor: '#DCDCDC', borderRadius: '10px', borderWidth: 'thin'}} dangerouslySetInnerHTML={ {__html: this.state.article.topBanner }}></div>}

        {/* Start Blog Details */}
        {this.state.article && 
          <div className="rn-blog-details pt--30 pb--70 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <div className="inner">
                      <BlockContent blocks={this.state.article.body} serializers={serializers}>
                      </BlockContent>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer /> 
      </React.Fragment>
    )
  }
}

// export default withRouter(SanityBlogArticle);
export default SanityBlogArticle;