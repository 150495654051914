const sanityClient = require('@sanity/client');

class SanityClient {
	constructor(projectId, dataset, token) {
		this.client = sanityClient({
  		projectId: projectId,
  		dataset: dataset,
			token: token,
		});
	}
	getClient() {
		return this.client;
	}
	async getBlogPosts() {
		return this.client
  		.fetch(
    		'*[_type=="post"]', // Query
  		)
  		.then(res => {
    		return res;
  		});
	}
}

export default SanityClient;