import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} </title>
                    <meta property="og:title" content={this.props.pageTitle} />
                    {this.props.pageDescription && <meta property="og:description" content={this.props.pageDescription}/>}
                    {this.props.pageDescription && <meta property="description" content={this.props.pageDescription}/>}
                    {this.props.pageImage && <meta property="og:image" content={this.props.pageImage} />}
                    {this.props.pageUrl && <meta property="og:url" content={this.props.pageUrl} />}
                    {this.props.pageType && <meta property="og:type" content={this.props.pageType} />}
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
