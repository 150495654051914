import React, {Component} from "react";
import Header from "../../component/header/Header";
// import PageHelmet from "../../component/common/Helmet";
import {Helmet} from 'react-helmet'
import ScrollToTop from "react-scroll-up";
import {FaTwitter, FaInstagram, FaFacebook, FaLinkedin} from "react-icons/fa";

const SocialShare = [
  {Social: <FaFacebook/>, link: 'https://www.facebook.com/12techllc'},
  {Social: <FaLinkedin/>, link: 'https://www.linkedin.com/company/12techllc'},
  /*{Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
  {Social: <FaTwitter /> , link: 'https://twitter.com/'},*/
]

class AllServices extends Component {
  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  renderHeaderImage() {
    let item = [];
    let key = this.props.location.pathname;
    switch (key) {
      case '/services/web-development':
        item.push(<div className="rn-page-title-area pb--190 web-development-cover-img-service " data-black-overlay="5">
          <div className="min-height-150">
            <Header logo="c-logo" logoname="logo.png"/>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient"> Full Stack Web Development</h2>
                </div>
              </div>
            </div>
          </div>
        </div>);
        break;
      case '/services/mobile-development':
        item.push(<div className="rn-page-title-area pb--190 mobile-development-cover-img-service "
                       data-black-overlay="5">
          <div className="min-height-150">
            <Header logo="c-logo" logoname="logo.png"/>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Mobile App Development</h2>
                </div>
              </div>
            </div>
          </div>
        </div>);
        break;
      case '/services/offshore-teams':
        item.push(
            <div className="rn-page-title-area pb--190 off-shore-cover-img " data-black-overlay="5">
              <div className="min-height-150">
                <Header logo="c-logo" logoname="logo.png"/>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                      <h2 className="title theme-gradient"> Offshore Teams</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/conversational-interfaces':
        item.push(
            <div className="rn-page-title-area pb--190 conversational-cover-img " data-black-overlay="5">
              <div className="min-height-150">
                <Header logo="c-logo" logoname="logo.png"/>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                      <h2 className="title theme-gradient"> Conversational Interfaces</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>);
        break;
      case '/services/cloud-devops':
        item.push(
            <div className="rn-page-title-area pb--190 cloud-dev-ops-cover-img cloud-dev-ops-cover-cover-img " data-black-overlay="5">
              <div className="min-height-150">
                <Header logo="c-logo" logoname="logo.png"/>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                      <h2 className="title theme-gradient"> Cloud DevOps</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/aws-cloud-solutions':
        item.push(
          <div className="rn-page-title-area pb--190 aws-cloud-sol-cover-img aws-cloud-sol-cover-cover-img " data-black-overlay="5">
            <div className="min-height-150">
              <Header logo="c-logo" logoname="logo.png"/>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="rn-page-title text-center pt--100">
                    <h2 className="title theme-gradient"> AWS Cloud Solutions</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
        break;
    }
    return item;
  }

  renderFooter() {
    return (<footer className="footer-area">
      <div className="footer-wrapper">
        <div className="row align-items-end row--0 text-dark">
          <div className="col-lg-6">
            <div className="footer-left-our-work">
              <div className="inner">
                <span>Ready To Do This</span>
                <h2>Let's get <br/> to work</h2>
                <a className="rn-button-style--2" href="/contact">
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer-right" data-black-overlay="6">
              <div className="row">
                  <div className="col-lg-6 col-sm-6 col-6">
                      <img className="AWSBadge" src="/assets/images/logo/tiered-badge.png" alt="AWS Consulting Partner Badge"/>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-6">
                      <img className="AWSBadge" src="/assets/images/logo/clutchBadge.png" alt="Top B2b Companies Clutch Badge"/>
                  </div>
              </div>
              <div className="row">
                {/* Start Single Widget  */}
                <div className="col-lg-6 col-sm-6 col-12">
                  <div className="footer-link">
                    <h4>Quick Links</h4>
                    <ul className="ft-link">
                      <li><a href="/#portfolio-sect">Work</a></li>
                      <li><a href="/#about-sect">About</a></li>
                      <li><a href="/contact">Let's Talk</a></li>
                    </ul>
                  </div>
                </div>
                {/* End Single Widget  */}
                {/* Start Single Widget  */}
                <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                  <div className="footer-link">
                    <h4>Say Hello</h4>
                    <ul className="ft-link">
                      <li><a href="mailto:info@12techllc.com">info@12techllc.com</a></li>
                      <li><a href="tel:+18333128324">(833) 312-8324</a></li>
                    </ul>

                    <div className="social-share-inner">
                      <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                        {SocialShare.map((val, i) => (
                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* End Single Widget  */}

                <div className="col-lg-12">
                  <div className="copyright-text">
                    <p>Copyright © 2020 12 Tech LLC. All Rights Reserved.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>);
  }

  renderService() {
    let item = [];
    let key = this.props.location.pathname;
    switch (key) {
      case '/services/web-development':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 mb-5 details">
                            <p>We offer two main web development models.
                              <ul className="liststyle">
                                <li>CMS Based</li>
                                <li>Custom Development</li>
                              </ul>
                            </p>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/web-development-0.jpg"
                                   alt="Service Images"/>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="details mt_md--30 mt_sm--30">
                              <h4 className="title">CMS Based</h4>
                              <p>
                                If a customer’s website needs are standard and existing out-of-box solutions exists then
                                we build
                                the site using a CMS solution:

                                <ul className="liststyle">
                                  <li>Wordpress</li>
                                  <li>Craft CMS</li>
                                  <li>Shopify (for e-commerce sites)</li>
                                </ul>

                                These are extremely popular and extensible web platforms that are sufficient for most
                                business
                                needs. Using such a solution significantly reduces development cost, time to market and
                                operational
                                costs.
                              </p>


                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                        {/* Start Single Area */}
                        <div className="row sercice-details-content align-items-center">
                          <div className="col-lg-6 col-12 order-2 order-lg-1">
                            <div className="details mt_md--30 mt_sm--30">

                              <h4 className="title">Custom Web Development</h4>
                              <p>

                                In some cases the project requirements are so custom that using an existing
                                out-of-the-box solution
                                doesn’t really provide much value, since it would need to be significantly customized.
                                Thus it is
                                instead better to implement a custom web platform from scratch and have full control on
                                the
                                platform.
                                <br/>
                                For such cases we use the following stack (MEAN/MERN):

                                <ul className="liststyle">
                                  <li>MongoDB</li>
                                  <li>ExpressJS</li>
                                  <li>ReactJS/Angular</li>
                                  <li>NodeJS</li>
                                </ul>

                                A CMS platform is still used to serve content, however it is used only in headless mode
                                i.e the CMS
                                doesn’t dictate the frontend.
                                For such projects we either use Sanity CMS or Craft CMS(in headless mode)
                                <br/>
                                We do all our deployments on the AWS cloud. We have AWS certified professionals as part
                                of our team
                                and have a deep understanding of the
                                entire AWS ecosystem.
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 order-1 order-lg-2">
                            <div className="thumb position-relative">
                              <img className="w-100" src="/assets/images/service/web-development-1.jpg"
                                   alt="Service Images"/>
                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 details mt_md--30 mt_sm--30">

                            <h4 className="title ">Custom Web Design</h4>
                            <p>
                              A professional web designer takes raw sketches/wireframes of the product and converts them
                              to high
                              fidelity designs.
                              The designs are produced in one of the following tools:
                              <ul className="liststyle">
                                <li>Figma</li>
                                <li>InVision</li>
                                <li>Adobe XD</li>
                              </ul>
                            </p>

                            <h4 className="title">Frontend Development</h4>
                            <p>
                              We implement web frontends using the following technologies
                              <ul className="liststyle">
                                <li>React and Redux or Angular</li>
                                <li>Bootstrap, Ant, Material Design or other industry and product specific design
                                  library
                                </li>
                                <li>SASS (css preprocessor)</li>
                              </ul>
                            </p>


                            <h4 className="title"> Backend Development</h4>
                            <p>For backends we follow two main models:</p>
                            <h5 className="title "> Serverless</h5>
                            <p> We implement Serverless backends using the following AWS technologies:</p>
                            <ul className="liststyle">
                              <li>AWS SAM framework</li>
                              <li>AWS Lambda</li>
                              <li>API Gateway</li>
                              <li>NodeJS</li>
                            </ul>
                            <h5 className="title ">Server based</h5>
                            <p>Traditional server based backends are implemented using the following</p>
                            <ul className="liststyle">
                              <li>Express and NodeJS</li>
                              <li>AWS EC2, ASG (Auto Scaling Groups) and ELB (Elastic Load Balancing)</li>
                            </ul>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/mobile-development':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-6 col-12">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/mobile-development.jpg"
                                   alt="Service Images"/>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12">
                            <div className="details mt_md--30 mt_sm--30">

                              <h4 className="title">CMS Based</h4>
                              <p>We offer two main web development models.</p>
                              <ul className="liststyle">
                                <li>CMS Based</li>
                                <li>Custom Development</li>
                              </ul>
                              <p>
                                If a customer’s website needs are standard and existing out-of-box solutions exists then
                                we build
                                the site using a CMS solution:
                              </p>
                              <ul className="liststyle">
                                <li>Wordpress</li>
                                <li>Craft CMS</li>
                                <li>Shopify (for e-commerce sites)</li>
                              </ul>
                              <p>
                                These are extremely popular and extensible web platforms that are sufficient for most
                                business
                                needs. Using such a solution significantly reduces development cost, time to market and
                                operational
                                costs.
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                        {/* Start Single Area */}
                        <div className="row sercice-details-content align-items-center">
                          <div className="col-lg-6 col-12 order-2 order-lg-1">
                            <div className="details mt_md--30 mt_sm--30">

                              <h4 className="title">Custom Web Development</h4>
                              <p>

                                In some cases the project requirements are so custom that using an existing
                                out-of-the-box solution
                                doesn’t really provide much value, since it would need to be significantly customized.
                                Thus it is
                                instead better to implement a custom web platform from scratch and have full control on
                                the
                                platform.
                                <br/><br/>
                                For such cases we use the following stack (MEAN/MERN):
                              </p>
                              <ul className="liststyle">
                                <li>MongoDB</li>
                                <li>ExpressJS</li>
                                <li>ReactJS/Angular</li>
                                <li>NodeJS</li>
                              </ul>
                              <p>
                                A CMS platform is still used to serve content, however it is used only in headless mode
                                i.e the CMS
                                doesn’t dictate the frontend.
                                For such projects we either use Sanity CMS or Craft CMS(in headless mode)
                                <br/>
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 col-12 order-1 order-lg-2">
                            <div className="thumb position-relative">
                              <img className="w-100" src="/assets/images/service/web-development-1.jpg"
                                   alt="Service Images"/>
                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 details mt_md--30 mt_sm--30">
                            <p className="pt-3">
                              We do all our deployments on the AWS cloud. We have AWS certified professionals as part
                              of our team
                              and have a deep understanding of the
                              entire AWS ecosystem.
                            </p>
                            <h4 className="title ">Custom Web Design</h4>
                            <p>
                              A professional web designer takes raw sketches/wireframes of the product and converts them
                              to high
                              fidelity designs.
                              The designs are produced in one of the following tools:
                            </p>
                            <ul className="liststyle">
                              <li>Figma</li>
                              <li>InVision</li>
                              <li>Adobe XD</li>
                            </ul>
                            <h4 className="title">Frontend Development</h4>
                            <p>
                              We implement web frontends using the following technologies
                            </p>
                            <ul>
                              <li>React and Redux or Angular</li>
                              <li>Bootstrap, Ant, Material Design or other industry and product specific design
                                library
                              </li>
                              <li>SASS (css preprocessor)</li>
                            </ul>

                            <h4 className="title"> Backend Development</h4>
                            <p>For backends we follow two main models:</p>
                            <h5 className="title "> Serverless</h5>
                            <p> We implement Serverless backends using the following AWS technologies:</p>
                            <ul className="liststyle">
                              <li>AWS SAM framework</li>
                              <li>AWS Lambda</li>
                              <li>API Gateway</li>
                              <li>NodeJS</li>
                            </ul>
                            <h5 className="title ">Server based</h5>
                            <p>Traditional server based backends are implemented using the following</p>
                            <ul className="liststyle">
                              <li>Express and NodeJS</li>
                              <li>AWS EC2, ASG (Auto Scaling Groups) and ELB (Elastic Load Balancing)</li>
                            </ul>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/offshore-teams':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-12 col-12">
                            <p>Offshore teams if done right is an extremely cost effective development model.
                              However it has significant challenges due to geographical, communication and time zone
                              differences;
                              and in a large number of cases it actually becomes counterproductive.
                              <br/>
                              <br/>
                              We have two decades of offshore staff augmentation experience and have perfected this art.
                              Following is our resolution to each of the major challenge with offshore resources:
                            </p>
                          </div>
                        </div>


                        <div className="row sercice-details-content align-items-center">
                          <div className="col-md-6 col-12">
                            <p className="title font-weight-bolder  ">Challenge: Communication is a difficult</p>
                            <p>
                              Each project is allocated a dedicated US based account manager who is the primary
                              point-of-contact
                              for
                              the
                              customer.
                              This person is dedicated to the client is always available to respond to client requests
                              <br/>
                              <br/>
                              In addition our developers speak fluent English, are thorough professionals and are
                              readily
                              available on
                              chat,
                              phone and video conferencing platforms.
                            </p>
                            <p className="title font-weight-bolder  ">Challenge: Time Zone differences makes it
                              difficult to
                              coordinate</p>
                            <p>

                              Our entire offshore team provides significant overlap with US time zones. The following
                              overlap is
                              provided
                              for all projects:
                              <ul className="">
                                <li>East Coast: Up to 3 pm ET</li>
                                <li>West Coast: Up to 1 pm PT</li>
                              </ul>
                            </p>

                          </div>
                          <div className="col-md-6 col-12">
                            <div className="off-shore-img-1">

                            </div>
                          </div>
                        </div>

                        <p className="title font-weight-bolder  ">Challenge: Quality Of Work</p>
                        <p>
                          Our developers go through a rigorous hiring process with multiple interviews covering various
                          aspects
                          of
                          software development and
                          interpersonal skills. Once on-boarded they work on in-house projects for at least 3 - 5 months
                          where
                          they
                          are trained and groomed further.
                          <br/>
                          <br/>
                          After the training projects conclude, their performance is reviewed and a decision is made
                          whether
                          they can
                          be allocated to client projects.
                          Developers are never allocated to client projects unless they undergo and pass this training
                          program.
                          <br/>
                          <br/>
                          Once they graduate from this program they are ready to serve our clients and code themselves
                          in
                          accordance
                          with our high standards.

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/conversational-interfaces':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-12">
                            <p>We specialize in building the following types of conversational interfaces</p>
                          </div>
                        </div>

                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-6 col-12">
                            <h3 className="title  ">Voice Apps</h3>
                            <h4 className="title  ">Alexa Skills</h4>
                            <p> Using ASK, NodeJS, Lambda</p>

                            <h4 className="title  ">Google Assistant Actions</h4>
                            <p>Using Dialogflow, NodeJS and Firebase to build Google Assistant Actions</p>

                            <h4 className="title  ">Chatbots</h4>
                            <p>To build custom Chatbots we use Dialogflow and Amazon Lex technologies</p>


                            <h4 className="title  ">Intelligent IVR</h4>
                            <p>For Intelligent IVRs we use Dialogflow alongwith technology integration like Twilio,
                              Cisco,
                              SigWire
                              etc</p>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="conversational-img-1"></div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/cloud-devops':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-12">
                            <h3 className="title">Cloud Migrations</h3>
                            <p>We migrate your existing on-premise workloads to the Cloud using the following models:

                              <ul>
                                <li>Re-hosting / lift-and-shift model</li>
                                <li>Re-platform / lift-and-optimize model</li>
                                <li>Re-purchase / drop-and-shop model</li>
                                <li> Refactor / re-architect</li>
                                <li>Retain / hybrid model</li>
                                <li> Retire</li>
                              </ul>
                            </p>

                          </div>
                        </div>

                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-6 col-12">

                            <h3 className="title  ">CI/CD Pipelines</h3>
                            <p>
                              This includes setup and management of continuous integration and continuous deployment
                              pipelines
                              for
                              client’s projects.
                              These pipelines also incorporate automated unit testing, integration testing, code
                              coverage and
                              analysis
                              phases.
                              They automate the deployment and release process of all environments like development,
                              integration, QA,
                              UAT
                              and production
                              An important aspect of this offering is IaC (Infrastructure-as-code);
                              We basically convert your cloud infrastructure into version controlled template files
                              (Terraform,
                              Cloudformation etc).
                              <br/>
                              <br/>
                              Having the entire infrastructure available as code gives clients ability to provision
                              various
                              staging
                              environments when required and
                              easily delete them when not it use to save cost
                            </p>
                          </div>
                          <div className="col-md-6  col-12">
                            <div className="dev-ops-img-1"></div>
                          </div>
                        </div>


                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-6  col-12">
                            <div className="web-development-img-2"></div>
                          </div>
                          <div className="col-md-6 col-12">
                            <br/>
                            <br/>
                            <h3 className="title  "> Production Systems Support</h3>
                            <p>
                              We provide dedicated resources that serve as an extension of your production support team.
                              Our
                              support
                              staff
                              would:

                              <ul>
                                <li>Follow existing processes or propose new ones if required</li>
                                <li>Address cloud and application issues and work with rest of the team to resolve
                                  them
                                </li>
                                <li>Do whatever is required to support production systems and resolve issues</li>
                              </ul>
                            </p>

                            <p>In addition we also implement the following to improve operational efficiency of
                              production
                              systems:
                              <ul>
                                <li>Realtime monitoring and alarms for various metrics (custom as well as built in)</li>
                                <li> Analytics dashboards using ELK and/or AWS CloudWatch</li>
                              </ul>
                            </p>

                          </div>
                        </div>

                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-md-12 col-12">
                            <h3 className="title  "> Cloud Optimization</h3>
                            <p>
                              This service includes carefully reviewing cloud resource usage and their costs.
                              Then we present a customized cost optimization plan that could result in a few hundred to
                              few
                              thousand
                              dollars cost savings.
                              For large accounts this could even lead to millions of dollars of savings over time.
                              <br/>
                              <br/>
                              Some of areas we focus on for cost reduction are as follows:
                              <ul>
                                <li> Identify unused and unattached resources</li>
                                <li>Identify and optimize idle resources</li>
                                <li> Right-size compute resources</li>
                                <li>Use reserved instances wherever possible</li>
                                <li>Identify opportunities to use spot instances</li>
                                <li> Infrastructure-as-code (IaC)</li>
                              </ul>
                            </p>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/services/aws-cloud-solutions':
        item.push(
          <div className="rn-service-details ptb--120 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="service-details-inner">
                    <div className="inner">
                      <div className="row sercice-details-content pb--80 align-items-center">
                        <div className="col-md-6 col-12">
                          <h3 className="title">Cloud Migrations</h3>
                          <p>We specialize in helping businesses make the transition to AWS. By following the best practices outlined in AWS Migration Acceleration Program (MAP) we make cloud migrations as seamless as possible. Let us show you how we can bring your applications to the cloud and help you harness the power of AWS.
                          </p>
                        </div>
                        <div className="col-md-6  col-12">
                          <div className="cloud-migrations-img" data-black-overlay="1"></div>
                        </div>
                      </div>

                      <div className="row sercice-details-content pb--80 align-items-center">
                        <div className="col-md-6 col-12">
                          <div className="infra-automation-img" data-black-overlay="1"></div>
                        </div>

                        <div className="col-md-6 col-12">

                          <h3 className="title  ">Infrastructure Automation</h3>
                          <p>
                          At 12 Tech, we know that managing cloud infrastructure using traditional methods is a daunting task. That's why we've made it our mission to simplify the process by automating the provisioning and management of your cloud infrastructure using cutting-edge IaC technologies like AWS Cloudformation, AWS CDK, and Terraform. 
.
                            <br/>
                            <br/>
                            With our expertise, you can say goodbye to the headache of manual server management and hello to a streamlined and efficient workflow. Plus, our automated systems allow for quick and easy scaling, ensuring you're always operating at peak performance.

                          </p>
                        </div>
                      </div>


                      <div className="row sercice-details-content pb--80 align-items-center">
                        <div className="col-md-6 col-12">
                          <br/>
                          <br/>
                          <h3 className="title"> Managed AWS Cloud Services</h3>
                          <p>
                          Our team of experts provides around-the-clock support and monitoring for your cloud services, giving you peace of mind knowing that any issues will be promptly addressed. 
                          <br/><br/>
                          With our managed cloud services, you can focus on growing and running your business, while we take care of the rest. Whether it's infrastructure management, security, or performance tuning, we have you covered. Say goodbye to worries about downtime or data loss, and welcome reliable cloud services with our managed cloud solutions.
                          </p>
                        </div>
                        <div className="col-md-6  col-12">
                          <div className="managed-cloud-services-img" data-black-overlay="1"></div>
                        </div>
                      </div>

                      <div className="row sercice-details-content pb--80 align-items-center">
                        <div className="col-md-6 col-12">
                          <div className="managed-devops-img" data-black-overlay="1"></div>
                        </div>
                        <div className="col-md-6 col-12">
                          <h3 className="title"> Managed DevOps</h3>
                          <p>
                          Our Managed DevOps program is geared towards providing your development teams with the day-to-day DevOps support needed to innovate and scale fast.
                          <br/>
                          Our DevOps support includes (but is not limited to) work around the following initiatives:
                            <ul>
                              <li> Infrastructure management via Infrastructure-as-Code (IaC) - AWS Cloudformation, CDK, Terraform</li>
                              <li>CI/CD Pipelines - Jenkins, AWS CodePipeline, AWS CodeBuild</li>
                              <li>Configuration Management (Config-as-Code implementations) - Chef, Puppet, Ansible etc</li>
                              <li>Compliance and Governance (Policy-as-Code implementations) - AWS Config, AWS Security Hub other compliance and security monitoring tools like t12 (https://t12.io)</li>
                              <li>Observability Solutions - AWS Cloudwatch, Datadog</li>
                            </ul>
                          </p>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
        break;
    }
    return item;
  }

  render() {
    return (
        <>
          <React.Fragment>

            {/* Start Pagehelmet  */}
            <Helmet>
              <title>12 Tech Services</title>
              <meta property="og:title" content="12 Tech Services" />
            </Helmet>
            {/* End Pagehelmet  */}

            {/*<Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>*/}
            {this.renderHeaderImage()}

            {this.renderService()}
            {/*Start Back To Top*/}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                {/*<FiChevronUp />*/}
              </ScrollToTop>
            </div>
            {/*End Back To Top*/}

            {this.renderFooter()}
          </React.Fragment>
        </>

    )
  }
}

export default AllServices;


// <div className="rn-service-details ptb--120 bg_color--1">
// <div className="container">
// <div className="row">
// <div className="col-lg-12">
// <div className="service-details-inner">
// <div className="inner">
// <div className="row sercice-details-content pb--80 align-items-center">
//
// </div>
//
// </div>
// </div>
// </div>
// </div>
// </div>
// </div>
