import React, {Component} from "react";
import ScrollToTop from 'react-scroll-up';
import {FiChevronUp} from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import SliderOne from "../component/slider/SliderOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import About from "../component/HomeLayout/homeOne/About";
import Portfolio from "../component/HomeLayout/homeOne/Portfolio";
import BlogContent from "../elements/blog/BlogContent";
import BrandTwo from "../elements/BrandTwo";
import Helmet from "../component/common/Helmet";
import BlogHelper from "../helpers/BlogHelper";
import Technologies from "../elements/Technologies";
import {Link} from "react-router-dom";

var blogHelper = new BlogHelper();

class MainDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      postList: null
    }
  }

  componentDidMount() {
    blogHelper.getRecentArticles().then(articles => {
      let posts = articles.slice(articles.length - 6, articles.length);
      posts = posts.sort((a, b) => new Date(b._createdAt) - new Date(a._createdAt));
      this.setState({
        postList: posts
      })
    }).catch((err) => {
      console.log(err);
    });
  }

  render() {
    // const PostList = BlogContent.slice(0 , 3);
    return (
        <div className="active-dark">
          <Helmet pageTitle="Top Software Development And DevOps | 12 Tech LLC"/>
          <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png"/>

          {/* Start Slider Area   */}
          <div className="slider-wrapper">
            <SliderOne/>
          </div>
          {/* End Slider Area   */}

          {/* Start About Area */}
          <div id="about-sect" className="about-area about-position-top pb--120 bg_color--1">
            <About/>
          </div>
          {/* End About Area */}

          {/* Start Service Area  */}
          <div id="services-sect" className="service-area ptb--80  bg_image bg_image--3">
            <div className="container">
              <ServiceTwo/>
            </div>
          </div>
          {/* End Service Area  */}

          {/* Start Portfolio Area */}
          <div id="portfolio-sect" className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner mb--55">
              <Portfolio/>
            </div>
          </div>
          {/* End Portfolio Area */}

          {/* Start CounterUp Area */}
          {/* {
          <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center">
                    <h3 className="fontWeight500">Software Fun Facts</h3>
                  </div>
                </div>
              </div>
              <CounterOne/>
            </div>
          </div>
          } */}
          {/* End CounterUp Area */}

          {/* Start Testimonial Area */}
          <div className="rn-testimonial-area bg_color--5 ptb--120">
            <div className="container">
              <Testimonial/>
            </div>
          </div>
          {/* End Testimonial Area */}

          {/* Start Blog Area */}
          {this.state.postList && <div className="rn-blog-area pt--120 bg_color--1 ">
            <div className="container">
              <div className="row align-items-end">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="section-title text-left">
                    <h2>Latest From The Blog</h2>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                    <Link to="/12tech-blog">
                      <a className="btn-transparent rn-btn-dark"><span className="text">View All Blog Posts</span></a>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="row mt--60 mt_sm--40">
                {this.state.postList.map((value, i) => (
                    <div className="col-lg-4 col-md-6 col-12" key={i}>
                      <div className="blog blog-style--1">
                        <div className="thumbnail">
                          <Link to={`12tech-blog/${value.slug.current}`}>
                            <a>
                              <img className="w-100" src={value.imageUrl} alt="Blog Image"/>
                            </a>
                          </Link>
                        </div>
                        <div className="content">
                          <p className="blogtype">{value.category}</p>
                          <h4 className="title"><a href={"/12tech-blog/" + value.slug.current}>{value.title}</a></h4>
                          <div className="blog-btn">
                            <a className="rn-btn text-white" href={"/12tech-blog/" + value.slug.current}>Read More</a>
                          </div>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
              <div className="row align-items-end">
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                  <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                    <Link to="/12tech-blog">
                      <a className="btn-transparent rn-btn-dark"><span className="text">View All Blog Posts</span></a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          {/* End Blog Area */}

          {/* Start Brand Area */}
          <div className="rn-brand-area brand-separation pb--120" style={{display: 'none'}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <BrandTwo/>
                </div>
              </div>
            </div>
          </div>
          {/* End Brand Area */}
          {/* Start Technologies Area */}
          <div className="rn-brand-area brand-separation pb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <Technologies/>
                </div>
              </div>
            </div>
          </div>
          {/* End Technologies Area */}
          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp/>
            </ScrollToTop>
          </div>
          {/* End Back To Top */}

          <Footer/>

        </div>
    )
  }
}

export default MainDemo;
