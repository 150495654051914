import React, { Component ,Fragment } from "react";
import BlogContent from "./BlogContent";
import BlogHelper from "../../helpers/BlogHelper";

var blogHelper = new BlogHelper();

class SanityBLogList extends Component{
    constructor(props) {
        super(props);
        this.state = {
            postList: null
        }
    }

    componentDidMount() {
        blogHelper.getRecentArticles().then( articles => {
            articles = articles.sort((a,b)=> new Date(b._createdAt) - new Date(a._createdAt));
            this.setState({
                postList: articles
            })
        });
    }

    render() {
        return (
             <Fragment>
                 {this.state.postList && <div className="row">
                    {this.state.postList.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href={"/12tech-blog/" + value.slug.current}>
                                        <img className="w-100" src={value.imageUrl} alt="Blog Images"/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{value.category}</p>
                                    <h4 className="title"><a href="/blog-details">{value.title}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href={"/12tech-blog/" + value.slug.current}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                     ))}
                 </div>
                }
             </Fragment>
        );
    }
}
export default SanityBLogList;
