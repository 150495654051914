import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class CloudformationService extends Component{  
  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  render(){
    return(
        <React.Fragment>
            
            <PageHelmet pageTitle='Automate Your AWS Infrastructure with 12 Tech' />

            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
            
            {/* main section */}
            <div className="rn-page-title-area pb--190 aws-cloud-sol-cover-img aws-cloud-sol-cover-cover-img " data-black-overlay="5">
              <div className="min-height-150">
                <Header logo="c-logo" logoname="logo.png"/>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="rn-page-title text-center pt--100">
                      <h2 className="title theme-gradient">Automate Your AWS Infrastructure with 12 Tech</h2>
                      <p>AWS CloudFormation for Increased Security and Efficiency</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end main section */}

            <div className="rn-service-details ptb--120 bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        <div className="row service-details-content pb--80 align-items-center">
                          <div className="col-md-6 col-12">
                            <h3 className="title">Automation Solutions with 12 Tech</h3>
                            <p>At 12 Tech, we are dedicated to helping you automate your AWS infrastructure. Our team of specialists are certified to provide you with the best solutions for your organization. We use CloudFormation, an Amazon Web Services (AWS) tool, to increase security and efficiency. Our automation solutions are designed to help you save time and money, as well as increase your system reliability.
                            </p>
                            <div className="mb--40">
                              <a className="rn-button-style--2" href={"/contact"}>Learn More</a>
                            </div>
                          </div>
                          <div className="col-md-6  col-12">
                            <div className="speed3-img" data-black-overlay="1"></div>
                          </div>
                        </div>
                        <div className="row service-details-content pb--80 align-items-center">
                          <div className="col-md-6 col-12">
                            <div className="speed4-img hidden-mobile" data-black-overlay="1"></div>
                          </div>

                          <div className="col-md-6 col-12">

                            <h3 className="title">Enhanced Security with CloudFormation</h3>
                            <p>Using CloudFormation, 12 Tech can help you create a secure environment for your AWS infrastructure. With CloudFormation, you are able to define the resources that you need to run your applications and services. This ensures that your infrastructure is configured and managed securely, with all the necessary access controls in place.
                            </p>
                            <a className="rn-button-style--2" href={"/contact"}>Learn More</a>
                          </div>
                        </div>  
                        <div className="row service-details-content pb--80 align-items-center">
                          <div className="col-md-6 col-12">
                            <h3 className="title">Automate with Efficiency and Reliability</h3>
                            <p>At 12 Tech, we help you automate your AWS infrastructure with CloudFormation. Our automation solutions are designed to help you save time and money, while ensuring that your system remains reliable. Our CloudFormation experts monitor your system for any changes or updates, allowing your business to run smoothly and efficiently.
                            </p>
                            <div className="mb--40">
                              <a className="rn-button-style--2" href={"/contact"}>Learn More</a>
                            </div>
                          </div>
                          <div className="col-md-6 col-12">
                            <div className="speed5-img" data-black-overlay="1"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer/>
        </React.Fragment>
    )
  }
}

export default CloudformationService;
