import React, {Component, Fragment} from "react";
import Header from "../../component/header/Header";
import PageHelmet from "../../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import Footer from "../../component/footer/Footer";
import {FaTwitter, FaInstagram, FaFacebook, FaLinkedin} from "react-icons/fa";
import {Link} from "react-router-dom";

const SocialShare = [
  {Social: <FaFacebook/>, link: 'https://www.facebook.com/12techllc'},
  {Social: <FaLinkedin/>, link: 'https://www.linkedin.com/company/12techllc'},
]
let PortfolioList = [

  {
    image: 'image-2',
    logo: 'assets/images/portfolio/logo/coach-ranking.png',
    category: 'Development',
    title: ' Coach Ranking',
    path: '/our-work/coach-ranking'
  },
  {
    image: 'image-3',
    logo: 'assets/images/portfolio/logo/city-of-mcallen.png',
    category: 'Development',
    title: ' City Of McAllen Voice Apps',
    path: '/our-work/city-of-mcallen'
  },
  {
    image: 'image-6',
    category: 'Development',
    logo: 'assets/images/portfolio/logo/medical-transportation.png',
    title: ' Medical Transportation'
  },
  {
    image: 'image-1',
    logo: 'assets/images/portfolio/logo/hotel-sales.svg',
    category: 'Development',
    title: ' US Hotels For Sale',
    path: '/our-work/us-hotels'
  },
]

class OurWork extends Component {
  componentDidMount() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  renderHeaderImage() {
    let item = [];
    let key = this.props.location.pathname;
    switch (key) {
      case '/our-work/us-hotels':
        item.push(<div className="rn-page-title-area pb--190 web-development-cover-img " data-black-overlay="5" key={'/our-work/us-hotels'}>
          <div className=" min-height-150">
            <Header logo="c-logo" logoname="logo.png"/>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">US HOTEL FOR SALE</h2>
                  <h5 className="text-white font-22">WEB DEVELOPMENT</h5>
                </div>
              </div>
            </div>
          </div>
        </div>);
        break;
      case '/our-work/coach-ranking':
        item.push(<div className="rn-page-title-area pb--190 coach-ranking-cover-img " data-black-overlay="5" key={'/our-work/coach-ranking'}>
          <div className=" min-height-150">
            <Header logo="c-logo" logoname="logo.png"/>
          </div>

          <div className="container ">
            <div className="row ">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100 ">
                  <h2 className="title theme-gradient">COACH RANKING</h2>
                  <h5 className="text-white font-22">Web and responsive Design and Development</h5>
                </div>
              </div>
            </div>
          </div>
        </div>);
        break;
      case '/our-work/ant-design':
        item.push(<div className="rn-page-title-area pb--190 ant-design-cover-img " data-black-overlay="5" key={'/our-work/ant-design'}>
          <div className="min-height-150">
            <Header logo="c-logo" logoname="logo.png"/>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100 ">
                  <h2 className="title theme-gradient">Ant Design React Admin</h2>
                  <h5 className="text-white font-22">Web and responsive Design and Development</h5>
                </div>
              </div>
            </div>
          </div>
        </div>);
        break;
      case '/our-work/city-of-mcallen':
        item.push(<div className="rn-page-title-area pb--190 ant-design-cover-img " data-black-overlay="5" key={'/our-work/city-of-mcallen'}>
          <div className="min-height-150">
            <Header logo="c-logo" logoname="logo.png"/>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100 ">
                  <h2 className="title theme-gradient">City Of McAllen Voice Apps </h2>
                  {/*<h5 className="text-white font-22">Web and responsive Design and Development</h5>*/}
                </div>
              </div>
            </div>
          </div>
        </div>);
        break;
    }
    return item;
  }

  renderOtherProjects() {
    PortfolioList = PortfolioList.filter((item) => item.path !== this.props.location.pathname);
    PortfolioList = PortfolioList.splice(0, 3);
    if(window.innerWidth <= 760){
      PortfolioList = PortfolioList.splice(0, 1);
    }
    return <div className="portfolio-slick-activation mt--70  row justify-content-around">
      {PortfolioList.map((value, index) => (
          <div className="portfolio col-xs-6 col-sm-6 col-md-3  col-lg-3 " key={index}>
            <div className="thumbnail-inner h-75 border-radius-50">
              <a href={value.path}>
                <div className={`thumbnail ${value.image}`}></div>
                <div className={`bg-blr-image ${value.image}`}></div>
              </a>
            </div>

            <div className="content">
              <div className="inner">
                <div className=" text-center ">
                  <a href={value.path}>
                    <div className="title h4 ">{value.title}</div>
                    <div className="text-dark">{value.category}</div>
                  </a>
                </div>
              </div>
            </div>


          </div>
      ))}
    </div>
  }

  renderService() {
    let item = [];
    let video1 = 'https://www.youtube.com/embed/1CVciQafb38';
    let key = this.props.location.pathname;
    switch (key) {
      case '/our-work/us-hotels':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1"  key={'/our-work/us-hotels'}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 ">
                            <div className="">
                              <h2 className="title">OVERVIEW</h2>
                              <hr className="line-separator-p"/>
                              <p className="font-22">
                                It is a long established fact that a reader will be distracted by the readable content
                                of
                                a page when looking at its layout. The point of using Lorem Ipsum is that it has a
                                more-or-less normal distribution of letters, as opposed to using 'Content here,
                                <br/>
                                <br/>
                                content
                                here', making it look like readable English. Many desktop publishing packages and web
                                page
                                editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum'
                                will uncover many web sites still in their infancy. Various versions have evolved over
                                the
                                years, sometimes by accident, sometimes on purpose (injected humour and the like
                              </p>
                            </div>

                          </div>

                          <div className="col-lg-12 col-12 ">
                            <div className="mx-5 px-5 font-22">
                              <div className="flex d-flex my-5 justify-content-around">
                                <div>
                                  <h5 className="text-muted">Client Name</h5>
                                  <p>US Hotel For Sale</p>
                                </div>
                                <div>
                                  <h5 className="text-muted">Release Date</h5>
                                  <p>April 10, 2019</p>
                                </div>
                                <div>
                                  <h5 className="text-muted">Project Type</h5>
                                  <p>Web Development</p>
                                </div>
                                <div>
                                  {/*<button className="btn bg-theme-gradient text-white">Visit Live site</button>*/}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/hotels0.png"
                                   alt="Service Images"/>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12 mt-5">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/hotels1.png"
                                   alt="Service Images"/>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="thumb mt-5">
                              {/*<img height={600} className="w-100" src="/assets/images/service/person.png"*/}
                              {/*     alt="Service Images"/>*/}
                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                        <div className="col-lg-12 col-12">
                          <div className="">
                            <div className=" my-5">
                              <h2 className="title text-center">Our More Projects</h2>
                              {this.renderOtherProjects()}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/our-work/coach-ranking':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1" key={'/our-work/coach-ranking'}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 ">
                            <div className="">
                              <h2 className="title">COACH RANKING WEBSITE</h2>
                              <hr className="line-separator-p"/>

                              <p className="font-weight-bold font-22">Share Your Biggest Breakthroughs, Discoveries &
                                "Aha"
                                Lessons</p>
                              <p className="font-22">
                                From Thousands Of Authors, Coaches, Teachers, Influencers,
                                Thought Leaders, Consultants & Service Providers. Take an active role in helping people
                                learn your lessons and discover valuable people, companies and messages.
                              </p>
                            </div>

                          </div>

                          <div className="col-lg-12 col-12 ">
                            <div className="mx-5 px-5">
                              <div className="flex d-flex my-5 justify-content-around font-22">
                                <div>
                                  <h5 className="text-muted">Client Name</h5>
                                  <p>Coachranking</p>
                                </div>
                                <div>
                                  <h5 className="text-muted">Release Date</h5>
                                  <p>October 28, 2020</p>
                                </div>
                                <div>
                                  <h5 className="text-muted">Project Type</h5>
                                  <p>Web Development</p>
                                </div>
                                <div>
                                  <a href="https://www.coachranking.com/">
                                    <button className="btn bg-theme-gradient text-white">Visit Live site</button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/coach-ranking/1.png"
                                   alt="Service Images"/>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12 mt-5">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/coach-ranking/2.png"
                                   alt="Service Images"/>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="thumb mt-5">
                              {/*<img height={600} className="w-100" src="/assets/images/service/person.png"*/}
                              {/*     alt="Service Images"/>*/}
                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                        <div className="col-lg-12 col-12">
                          <div className="">
                            <div className=" my-5">
                              <h2 className="title text-center">Our More Projects</h2>
                              {this.renderOtherProjects()}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/our-work/ant-design':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1" key={'/our-work/ant-design'}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 ">
                            <div className="">
                              <h2 className="title">Ant Design React Admin</h2>
                              <hr className="line-separator-p"/>

                              <p className="font-weight-bold font-22">Share Your Biggest Breakthroughs, Discoveries &
                                "Aha" Lessons</p>
                              <p className="font-22">
                                Ant admin is a powerful admin template built with Ant Design guideline in mind, it comes
                                with tons of useful features and more on the way
                              </p>
                            </div>

                          </div>

                          <div className="col-lg-12 col-12 ">
                            <div className="mx-5 px-5">
                              <div className="flex d-flex my-5 justify-content-around font-22">
                                <div>
                                  <h5 className="text-muted">Client Name</h5>
                                  <p>Ant Design</p>
                                </div>
                                <div>
                                  <h5 className="text-muted">Release Date</h5>
                                  <p>Nov 30, 2020</p>
                                </div>
                                <div>
                                  <h5 className="text-muted">Project Type</h5>
                                  <p>Web Development</p>
                                </div>
                                <div>
                                  <a href="https://cranford-demo.t12.design/">
                                    <button className="btn bg-theme-gradient text-white">Visit Live site</button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/ant-design/1.png"
                                   alt="Service Images"/>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12 mt-5">
                            <div className="thumb">
                              <img className="w-100" src="/assets/images/service/ant-design/2.png"
                                   alt="Service Images"/>
                            </div>
                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="thumb mt-5">
                              {/*<img height={600} className="w-100" src="/assets/images/service/person.png"*/}
                              {/*     alt="Service Images"/>*/}
                            </div>
                          </div>
                        </div>
                        {/* End Single Area */}

                        <div className="col-lg-12 col-12">
                          <div className="">
                            <div className=" my-5">
                              <h2 className="title text-center">Our More Projects</h2>
                              {this.renderOtherProjects()}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
      case '/our-work/city-of-mcallen':
        item.push(
            <div className="rn-service-details ptb--120 bg_color--1" key={'/our-work/city-of-mcallen'}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="service-details-inner">
                      <div className="inner">
                        {/* Start Single Area */}
                        <div className="row sercice-details-content pb--80 align-items-center">
                          <div className="col-lg-12 col-12 ">
                            <div className=" ">
                              <h2 className="title">City Of McAllen Voice Apps</h2>
                              <hr className="line-separator-p"/>

                              <p className="font-weight-bold font-22"> We built the city of McAllen, TX 's official
                                Alexa skill and Google assistant action.</p>
                              <p className="font-22">
                                These provide information about city services, facilities and departments, such as
                                library hours and branch locations, how to obtain building permits, when and where
                                McAllen City Commission meetings are held as well as how to report concerns

                              </p>
                            </div>

                          </div>

                          <div className="col-lg-12 col-12">
                            <div className="text-center">
                              <div className=" my-5 ">
                                <iframe src={video1} width="800" height="500" />
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* End Single Area */}

                        <div className="col-lg-12 col-12">
                          <div className="">
                            <div className=" my-5">
                              <h2 className="title text-center">Our More Projects</h2>
                              {this.renderOtherProjects()}
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
        break;
    }
    return item;
  }


  render() {
    return (
        <>
          <React.Fragment>

            {/* Start Pagehelmet  */}
            <PageHelmet pageTitle='Service Details'/>
            {/* End Pagehelmet  */}

            {this.renderHeaderImage()}

            {this.renderService()}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                {/*<FiChevronUp />*/}
              </ScrollToTop>
            </div>

            <Footer/>
          </React.Fragment>
        </>

    )
  }
}

export default OurWork;
