import React, {Component} from "react";
import Slider from "react-slick";
import {portfolioSlick2, portfolioSlick} from "../../../page-demo/script";
import {Link} from "react-router-dom";


const PortfolioList = [

  {
    image: 'image-3',
    logo: 'assets/images/portfolio/logo/city-of-mcallen.png',
    category: 'Development',
    path: '/our-work/city-of-mcallen',
    title: ' City Of McAllen Voice Apps'
  },
  {
    image: 'image-2',
    logo: 'assets/images/portfolio/logo/coach-ranking.png',
    category: 'Development',
    title: ' Coach Ranking',
    path: '/our-work/coach-ranking'
  },
  {
    image: 'image-1',
    logo: 'assets/images/portfolio/logo/hotel-sales.svg',
    category: 'Development',
    title: ' US Hotels For Sale',
    path: '/our-work/us-hotels'
  },
  {
    image: 'image-7',
    category: 'Development',
    logo: 'assets/images/portfolio/logo/cranford.png',
    title: 'Ant Design React Admin',
    path: '/our-work/ant-design'
  },
  {
    image: 'image-6',
    category: 'Development',
    logo: 'assets/images/portfolio/logo/medical-transportation.png',
    // title: ' Medical Transportation Web App'
    title: ' First Class Medical Transportation Web App'
  },
  // {
  //   image: 'image-4',
  //   category: 'Development',
  //   title: ' US Naturalization Exam'
  // },
  // {
  //   image: 'image-5',
  //   category: 'Development',
  //   title: ' Share Rewards App'
  // },
]

class Portfolio extends Component {
  render() {
    let title = 'Our Works',
        description = 'Custom web portals, SaaS platforms, mobile apps, chat bots ... We do it all!';
    return (
        <React.Fragment>
          <div className="portfolio-wrapper">
            <div className="container h-100">
              <div className="row">
                <div className="col-lg-6">
                  <div className="section-title">
                    <h2>{title}</h2>
                    <p>{description}</p>
                  </div>
                </div>
                <div className="col-lg-12 col-12 h-100 overflow-x-hidden min-height-750 h-100">
                  <div className="portfolio-slick-activation mt--70 mt_sm--40 ">
                    <Slider {...portfolioSlick2}>
                      {PortfolioList.map((value, index) => (
                          <div className="portfolio w-80 mx-3" key={index}>
                            <div className="thumbnail-inner ">
                              <div className={`thumbnail ${value.image}`}></div>
                              <div className={`bg-blr-image ${value.image}`}></div>
                            </div>

                            <div className="content">
                              <div className="inner">
                                {/*<p>{value.category}</p>*/}
                                <img className="m-auto" src={value.logo}></img>
                                <div className="mt-3" style={{height: "30px"}}>
                                  <h5 className="text-align-bottom text-white">{value.title}</h5>
                                </div>
                                <div className="portfolio-button">
                                  <Link className="rn-btn" to={value.path}>Case Study
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
    )
  }
}

export default Portfolio;
