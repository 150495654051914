import SanityClient from './SanityClient';

const SANITY_BLOG_PROJECT_ID = "6br2a5b1";
const SANITY_BLOG_DATASET = "production";

class BlogHelper {
  constructor() {
    this.sanityClient = new SanityClient(SANITY_BLOG_PROJECT_ID, SANITY_BLOG_DATASET);
  }
  async getRecentArticles() {
		const query = '*[_type == "post"]{title,slug,"imageUrl": mainImage.asset->url,_createdAt} | order(_createdAt)'

    return this.sanityClient.getClient().fetch(query);
  }

  async getArticle(articleSlug) {
      const query = '*[_type == "post" && slug.current == $postSlug]{title,slug,\'authorName\': author->name,_createdAt,topBanner,"imageUrl": mainImage.asset->url,description, body[]{..., "asset": asset->}}'
      const params = {postSlug: articleSlug};
      return this.sanityClient.getClient().fetch(query, params);
  }
}

export default BlogHelper;
