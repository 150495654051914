import React, { Component } from "react";
import {FaTwitter ,FaInstagram ,FaFacebook , FaLinkedin} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebook /> , link: 'https://www.facebook.com/12techllc'},
    {Social: <FaLinkedin /> , link: 'https://www.linkedin.com/company/12techllc'},
    /*{Social: <FaInstagram /> , link: 'https://www.instagram.com/'},
    {Social: <FaTwitter /> , link: 'https://twitter.com/'},*/
]
class Footer extends Component{
    render(){
        return(
            <React.Fragment>
                <footer className="footer-area">
                    <div className="footer-wrapper">
                        <div className="row align-items-end row--0">
                            <div className="col-lg-6">
                                <div className="footer-left-our-work">
                                    <div className="inner">
                                        <span>Ready To Do This</span>
                                        <h2>Let's get <br /> to work</h2>
                                        <a className="rn-button-style--2" href="/contact">
                                            <span>Contact Us</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-right" data-black-overlay="6">
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-4 col-4">
                                            <img className="AWSBadge" src="/assets/images/logo/tiered-badge.png" alt="AWS Consulting Partner Badge"/>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-4">
                                            <img className="AWSBadge" src="/assets/images/logo/clutchBadge.png" alt="Top B2b Companies Clutch Badge"/>
                                        </div>
                                        <div className="col-lg-4 col-sm-4 col-4">
                                        <a href="https://upcity.com/mobile-app-development/newark-nj?spotlight=profiles%2F12-tech-llc%2Fcranford"> <img className="AWSBadge" src="https://upcity-marketplace.s3.amazonaws.com/badge/169/basic_full_color/8da23bb5659915ec6a30216ad4800541.png" alt="TOP MOBILE APP DEVELOPER" /> </a>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-4 col-sm-4 col-4">
                                        <a href="https://www.expertise.com/nj/elizabeth/software-development" style={{display: "inline-block", border: 0}} ><img style={{display: "block"}} width="140" height="160" src="https://res.cloudinary.com/expertise-com/image/upload/w_auto/remote_media/awards/nj_elizabeth_software-development_2023_inverse.svg" alt="Top Software Development Company in Elizabeth" /></a>
                                        </div>
                                    </div>

                                    <div className="row">
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12">
                                            <div className="footer-link">
                                                <h4>Quick Links</h4>
                                                <ul className="ft-link">
                                                    <li><a href="/#portfolio-sect">Work</a></li>
                                                    <li><a href="/#about-sect">About</a></li>
                                                    <li><a href="/contact">Let's Talk</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}
                                        {/* Start Single Widget  */}
                                        <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                                            <div className="footer-link">
                                                <h4>Say Hello</h4>
                                                <ul className="ft-link">
                                                    <li><a href="mailto:info@12techllc.com">info@12techllc.com</a></li>
                                                    <li><a href="tel:+18333128324">(833) 312-8324</a></li>
                                                </ul>

                                                <div className="social-share-inner">
                                                    <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                                                        {SocialShare.map((val , i) => (
                                                            <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                                        ))}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </div>
                                        {/* End Single Widget  */}

                                        <div className="col-lg-12">
                                            <div className="copyright-text">
                                                <p>Copyright © 2022 12 Tech LLC. All Rights Reserved.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        )
    }
}
export default Footer;
