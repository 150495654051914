// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

// Common Layout
// import Layout from "./component/common/App";

// Dark Home Layout 
import DarkMainDemo from './dark/MainDemo';

// Element Layout
import Contact from "./elements/Contact";

import PortfolioDetails from "./elements/PortfolioDetails";
import error404 from "./elements/error404";
import SanityBlog from "./elements/SanityBlog";
import SanityBlogDetails from "./elements/SanityBlogDetails";
import LeadMagnetDelivery from "./landing-pages/LeadMagnetDelivery";
import AllServices from "./elements/service/AllServices";
import OurWork from "./elements/service/OurWork";

import {BrowserRouter, Switch, Route, Redirect} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import UnderConstruction from './elements/UnderConstruction';
import PrivacyPolicy from './elements/PrivacyPolicy';
import ServiceCloudFormation from './elements/CloudformationService';

const lmMainHeading = "Web Development Ultimate Checklist";
const lmSubTitle = "We build web portals and platforms within 2 months ... Find out how?";
const lmPrimaryButtonText = "Download";
const lmMainImage = "https://12techpub.s3.amazonaws.com/web/12techllc/assets/web-design-2038872_1280.jpg";

class Root extends Component {
  render() {
    return (
        <BrowserRouter basename={'/'}>
          <Switch>
            <Route exact path={`${process.env.PUBLIC_URL}/`} component={DarkMainDemo}/>
            <Route exact path={`${process.env.PUBLIC_URL}/contact`} component={Contact}/>
            {/*<Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={PortfolioDetails}/>*/}
            <Route exact path={`${process.env.PUBLIC_URL}/portfolio-details`} component={UnderConstruction}/>
            <Route exact path={`${process.env.PUBLIC_URL}/service-details`} component={UnderConstruction}/>
            <Route path={`${process.env.PUBLIC_URL}/services`} component={AllServices}/>
            <Route path={`${process.env.PUBLIC_URL}/our-work`} component={OurWork}/>
            <Route exact path={`${process.env.PUBLIC_URL}/12tech-blog`} component={SanityBlog}/>
            <Route path={`${process.env.PUBLIC_URL}/12tech-blog/:articleSlug`} component={SanityBlogDetails}/>
            <Route exact path={`${process.env.PUBLIC_URL}/privacy-policy`} component={PrivacyPolicy}/>

            <Route path={`${process.env.PUBLIC_URL}/lp/web-platform-dev-checklist`}
                   component={() => <LeadMagnetDelivery mainHeading={lmMainHeading} subTitle={lmSubTitle}
                                                        primaryButtonText={lmPrimaryButtonText}
                                                        mainImage={lmMainImage}/>}/>

            <Route exact path={`${process.env.PUBLIC_URL}/aws-infrastructure-automation-with-cloudformation`} component={ServiceCloudFormation}/>

            <Redirect from='/blog/:articleSlug' to='/12tech-blog/:articleSlug'/>
            <Redirect from='/fullstack-blog/:articleSlug' to='/12tech-blog/:articleSlug'/>
            <Redirect from='/fullstack-blog/:postTitle' to='/12tech-blog/:articleSlug'/>
            <Redirect from='/blog-reader/:postTitle' to='/12tech-blog/:articleSlug'/>

            <Route path={`${process.env.PUBLIC_URL}/404`} component={error404}/>
            <Route component={error404}/>

          </Switch>
        </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
serviceWorker.register();
