import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'About',
        description = '12 Tech is a cloud native custom software development and cloud services company. We specialize in custom web development, mobile app development and cloud services consulting';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-img.png" alt="About Images"/>
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>We are designers and builders with a passion for making an impact by building great software</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Our Philosophy</h3>
                                                <p>As a technology solutions provider, it is our responsibility to relieve the client of all technology and its operational issues.
                                                    Clients should be able to fully focus on growing their business and customers.<br/>
                                                    Our sole objective is client success; all solutions we propose and implement should be based on putting our customers best interest first

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;
