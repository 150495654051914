import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>Working with 12 Tech for the City of McAllen’s Alexa and Google smart speaker apps was an amazing experience. Syed and the team really took the time to understand our goal for the project and worked quickly to meet the deadlines set. The testing and other key phases of the implementation have made our City of McAllen Alexa and Google apps truly smart!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Robert Acosta</span> - Director of IT, City Of McAllen, TX</h6>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>12 Tech took my vision and made a great app! The team was great to work with!</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Glenn Doman </span> - President, Elevation Services</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="rn-testimonial-content text-center">
                                    <div className="inner">
                                        <p>I hired 12 Tech back in 2018 to work on a very complicated data analytics platform. Not only did they completed everything on time but the software was extremely robust and bug free. Ever since we have regularly utilized their services in various custom software development projects. Extremely reliable and efficient group of individuals. Highly recommended!.</p>
                                    </div>
                                    <div className="author-info">
                                        <h6><span>Nabeel </span> - CTO, ATS</h6>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabList className="testimonial-thumb-wrapper">
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/city-of-mcallen.png" alt="City Of McAllen"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/elevation-services.png" alt="Elevation Services"/>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab>
                                    <div className="testimonial-thumbnai">
                                        <div className="thumb">
                                            <img src="/assets/images/client/atsgen.png" alt="ATS"/>
                                        </div>
                                    </div>
                                </Tab>
                            </TabList>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;