import React, { Component } from "react";
import { FaGlobe } from "react-icons/fa";
import {AiOutlineMobile} from "react-icons/ai";
import {MdSettingsVoice} from "react-icons/md";
import {BsPeopleFill} from "react-icons/bs";

const ServiceList = [
    {
        icon: <FaGlobe />,
        title: 'Custom Software Development',
        description: 'Custom websites, platforms, marketplaces and mobile app development'
    },
    {
        icon: <MdSettingsVoice />,
        title: 'Cloud Services',
        description: 'DevOps, Migrations, Cloud Native Development and Managed Services'
    },
    {
        icon: < BsPeopleFill/>,
        title: 'IT Staff Augmentation',
        description: 'Onshore and offshore staff augmentation for Development, DevOps and Solutions Architect resources'
    },
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1 t12-service">
                                <div className="icon">
                                    {/*<img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>*/}
                                    {val.icon}
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
