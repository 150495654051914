import React, {Component} from "react";
import {FiGlobe, FiUsers} from "react-icons/fi";
import {AiOutlineMobile, AiOutlineCloud, AiOutlineTool} from "react-icons/ai";
import {MdFastForward} from "react-icons/md";
import {Link} from "react-router-dom";

const ServiceList = [
  {
    icon: <FiGlobe/>,
    title: 'Web Development',
    description: 'Development of web portals, platforms, online marketplaces and custom website design.',
    link: '/services/web-development'
  },
  {
    icon: <AiOutlineMobile/>,
    title: 'Mobile App Development',
    description: 'Development of native apps for Android and iOS platforms.',
    link: '/services/mobile-development'
  },
  {
    icon: <FiUsers/>,
    title: 'Offshore Teams',
    link: '/services/offshore-teams',
    description: 'Hire from our talented pool of super star developers. Get highly competitive rates, overlap with US timezones and dedicated account managers with excellent communication and customer service skills.'
  },
  {
     icon: <MdFastForward/>,
     title: 'Data Labeling and Data Annotation ',
     description: 'Using AI and ML to help customers to build better models',
     link: '#'
  },
  {
    icon: <AiOutlineCloud/>,
    title: 'AWS Cloud Solutions',
    description: 'AWS Cloud Migrations, DevOps and Managed Services.',
    link: '/services/aws-cloud-solutions'
  },
  {
    icon: <AiOutlineTool/>,
    title: 'AWS Infrastructure Automation with CloudFormation',
    description: 'AWS Infrastructure provisioning, automation and IaC with AWS CloudFormation',
    link: '/aws-infrastructure-automation-with-cloudformation'
  }
]

class ServiceTwo extends Component {
  render() {
    let title = 'Services',
        description = '12 TECH is a software powerhouse; we focus and shine on the difficult stuff like building custom software and complex applications. When you work with us you can be certain that you are working with a highly qualified and dedicated team with no technical limitations; A team that would always provide the highest quality deliverables and services.';
    return (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-4 col-12">
              <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                <h2 className="title">{title}</h2>
                <p className="text-white">{description}</p>
                <div className="service-btn">
                  <a className="btn-transparent rn-btn-dark" href="/contact"><span className="text">Request Free Consultation</span></a>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-12 mt_md--50">
              <div className="row service-one-wrapper">
                {ServiceList.map((val, i) => (
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                      <Link to={val.link}>
                        <div className="service service__style--2">
                          <div className="icon">
                            {val.icon}
                          </div>
                          <div className="content">
                            <h3 className="title">{val.title}</h3>
                            <p className="text-white">{val.description}</p>
                          </div>
                        </div>
                      </Link>
                    </div>
                ))}
              </div>
            </div>
          </div>
        </React.Fragment>
    )
  }
}

export default ServiceTwo;
