import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <h2>Our Core Technologies</h2>
                <ul className="brand-style-2 technologies">
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/react.png" alt="ReactJS"/>
                        </div>
                        <span className="text-white text-center"> React.js</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/nodejs.png" alt="NodeJS"/>
                        </div>
                        <span className="text-white text-center">NodeJS</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/angular.png" alt="AngularJS"/>
                        </div>
                        <span className="text-white text-center">AngularJS</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110 ">
                        <img src="/assets/images/technologies/js.png" alt="Javascript"/>
                        </div>
                        <span className="text-white text-center">JavaScript</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110 ">
                        <img src="/assets/images/technologies/html5.png" alt="HTML"/>
                        </div>
                        <span className="text-white text-center">HTML 5</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/css.png" alt="CSS"/>
                        </div>
                        <span className="text-white text-center">CSS 3</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110 ">
                        <img src="/assets/images/technologies/mongodb.png" alt="MongoDB"/>
                        </div>
                        <span className="text-white text-center">MongoDB</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/postgresql.png" alt="PostgreSQL"/>
                        </div>
                        <span className="text-white text-center">PostgreSQL</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/redux.png" alt="Redux"/>
                        </div>
                        <span className="text-white text-center">Redux</span>
                    </li>
                    <li className="inline-grid">
                        <div className="h-110">
                        <img src="/assets/images/technologies/graphql.png" alt="GraphQL"/>
                        </div>
                        <span className="text-white text-center">GraphQL</span>
                    </li>
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
